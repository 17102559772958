import React, { FC, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./SectionModule.module.scss"

const bem = create(styles, "SectionModule")

// ContentfulSectionModuleFieldsData["variant"]
type SectionModuleVariant =
  | "default"
  | "background-grey"
  | "highlight-field"
  | "explanation-box"
  | "full-width"
  | undefined

// ContentfulSectionModuleFieldsData["contentWidth"]
type ContentWidth = "large" | "small" | "full-width"

export type SectionModuleProps = PropsWithChildren<{
  variant?: SectionModuleVariant
  contentWidth?: ContentWidth
}>

/**
 * The backbone of the content page templates.
 *
 * It is the most freely documentable and editable module and is used to display all regular content apart from the booking routes.
 */
export const SectionModule: FC<SectionModuleProps> = ({
  variant = "default",
  contentWidth = "small",
  children,
}) => {
  if (!children) {
    return null
  }

  const modifiers = {
    [variant]: true,
    [contentWidth]: true,
  }

  return (
    <div className={bem(undefined, modifiers)}>
      <div className={bem("content", modifiers)}>{children}</div>
    </div>
  )
}
